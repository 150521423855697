/* eslint-disable react/no-array-index-key */
import { Button, Divider, TextField, Typography, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import SelectInput from "components/Select/SelectInput";

import { v4 as uuidv4 } from "uuid";
import React, { useEffect, useState } from "react";
import { Add, AddCircle, Cancel, Remove } from "@mui/icons-material";
import CreatableSelect from "components/Select/CreatableSelect";

export const getUniqueID = () => uuidv4().replace(/-/g, "");
export const responseTypes = ["select", "multi-select"];
const optionsForResponse = [
  { value: "Not Good", label: "Not Good", text: "Not Good", id: getUniqueID() },
  { value: "Okay", label: "Okay", text: "Okay", id: getUniqueID() },
  { value: "Yes", label: "Yes", text: "Yes", id: getUniqueID() },
  { value: "No", label: "No", text: "No", id: getUniqueID() },
];

function Message({ data, handleData, initialData, dataTypes, componentName }) {
  const [selectedTypes, setSelectedTypes] = useState(
    data.length > 0 && componentName === "Response" ? data.map((item) => item.type) : []
  );

  const handleChange = (value, sectionType, index, optionIndexOfResponse) => {
    let temp;
    if (sectionType === "type") {
      temp = selectedTypes;
      temp[index] = value;
      setSelectedTypes([...temp]);
    }
    if (componentName === "Response" && sectionType === "content") {
      temp = data;
      temp[index][sectionType][optionIndexOfResponse] = value;
      handleData(temp, componentName);
      return;
    }
    temp = data;
    temp[index][sectionType] = value;
    handleData(temp, componentName);
  };

  const handleAdd = () => {
    let temp = data;
    const selectedTypesTemp = selectedTypes;
    if (temp.length < 1) {
      temp.push({ ...initialData, id: getUniqueID() });
      selectedTypesTemp.push(initialData.type);
      setSelectedTypes([...selectedTypesTemp]);
      handleData(temp, componentName);
    } else {
      temp = [...data];

      if (
        temp[temp.length - 1].type &&
        (componentName === "Response" || temp[temp.length - 1].content)
      ) {
        selectedTypesTemp.push(initialData.type);
        setSelectedTypes([...selectedTypesTemp]);
        temp.push({ ...initialData, id: getUniqueID() });
        handleData(temp, componentName);
      }
    }
  };
  const handleRemove = (index) => {
    const temp = data;
    const selectedTypesTemp = selectedTypes;
    if (index > -1) {
      temp.splice(index, 1);
      selectedTypesTemp.splice(index, 1);
      setSelectedTypes(selectedTypesTemp);
    }
    handleData(temp, componentName);
  };

  const handleAddOption = () => {
    const temp = data;
    if (temp.length > 0) {
      if (temp[temp.length - 1].content.length < 1) {
        temp[temp.length - 1].content.push({ value: "", label: "", text: "", id: getUniqueID() });
        handleData(temp, componentName);
      } else if (temp[temp.length - 1].content.at(-1).value) {
        temp[temp.length - 1].content.push({ value: "", label: "", text: "", id: getUniqueID() });
        handleData(temp, componentName);
      }
    }
  };
  const handleRemoveOption = (index) => {
    const temp = data;
    if (index > -1) {
      temp[temp.length - 1].content.splice(index, 1);
      handleData(temp, componentName);
    }
  };

  return (
    <Box
      sx={{
        marginTop: 4,
        maxHeight: "40vh",
        overflow: "scroll",
        border: "2px solid #D3D3D3",
        borderRadius: "5px",
        overflowX: "hidden",
        padding: "10px",
      }}
    >
      <Typography variant="h6" component="h6" sx={{ marginBottom: 2 }}>
        {componentName}
      </Typography>
      {data &&
        data.map((item, index) => (
          <Box key={item.id}>
            <Divider />
            <Typography variant="body1" component="h6" sx={{ margin: 1 }}>
              {index + 1}
            </Typography>
            <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
              <Box sx={{ width: "25%" }}>
                <SelectInput
                  index={index}
                  selectedValue={item.type}
                  options={dataTypes}
                  // disabled={index < data.length - 1}
                  handleChangeValue={handleChange}
                  sectionType="type"
                />
              </Box>

              <Box sx={{ width: "68%" }}>
                {componentName === "Response" ? null : (
                  <TextField
                    // disabled={index < data.length - 1}
                    id="outlined-multiline-static"
                    label="Add Content"
                    value={item && item.content && item.content}
                    multiline
                    onChange={(e) => handleChange(e.target.value, "content", index)}
                    sx={{ marginBottom: 1 }}
                    rows={2}
                    fullWidth
                  />
                )}
                {/* {selectedTypes.length > 0 && responseTypes.includes(selectedTypes[index].value) ? ( */}
                {(data.length > 0 && item.type && item.type.value === "select") ||
                item.type.value === "multi-select" ? (
                  <>
                    {" "}
                    {data[index].content &&
                      data[index].content.map((option, i) => (
                        <Box key={i}>
                          <CreatableSelect
                            options={optionsForResponse}
                            SelectedValue={option && option}
                            // disabled={i < data[index].content.length - 1}
                            handleChangeValue={handleChange}
                            sectionType="content"
                            index={index}
                            optionIndexOfResponse={i}
                          />
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="label"
                            onClick={() => {
                              handleRemoveOption(index);
                            }}
                          >
                            <Cancel />
                          </IconButton>
                        </Box>
                      ))}
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="label"
                      onClick={() => {
                        handleAddOption();
                      }}
                    >
                      <AddCircle />
                    </IconButton>
                  </>
                ) : null}
              </Box>
              <Box sx={{ width: "5%" }}>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                  onClick={() => {
                    handleRemove(index);
                  }}
                >
                  <Remove />
                </IconButton>
              </Box>
            </Box>
          </Box>
        ))}

      <IconButton color="primary" aria-label="upload picture" component="label" onClick={handleAdd}>
        <Add />
      </IconButton>
    </Box>
  );
}

export default Message;
