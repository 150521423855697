/* eslint-disable import/no-mutable-exports */
import { readFromLocalStorage } from "services/localStorage";

const axios = require("axios");

export const environment = "prod"; // environment selection
export const sopsList = [
  { value: `${environment}_engine_not_started`, label: "Engine not started" },
  { value: `${environment}_engine_not_started_new`, label: "Engine not started new" },
  { value: `${environment}_ac_cooling_not_effective`, label: "AC cooling not effective" },
  { value: `${environment}_engine_not_started_ai`, label: "Engine not started AI" },
];
// to add more SOPs just add new element in the sopsList

const API_URL = "";
export const urlEndpoint =
  (readFromLocalStorage("selected_sop") && readFromLocalStorage("selected_sop").value) ||
  sopsList[0].value;

// vda_data, stage_vda_data dev_vda_data

// async () => {
//   urlEndpoint = (await readFromLocalStorage("selected_sop")) || sopsList[0];
// };

const headers = {
  // "x-origin": 1,
  // "x-version": 1,
  // "x-device-id": "vda.device",
  // "x-platform": 1,
  "Content-Type": "application/json",
};

const requestApi = async (resourcePath, method, params) => {
  const url = API_URL + resourcePath;

  if (resourcePath === "/auths/login") {
    delete headers["x-auth"];
  }
  let response;
  if (["POST", "PUT", "DELETE"].indexOf(method) > -1 && params) {
    response = await axios({ url, method, data: params, headers });
    return response.data;
  }
  response = await axios({ url, method, headers });
  return response.data;
};

export default requestApi;
