/* eslint-disable consistent-return */
import { ThemeProvider, CssBaseline, createTheme, Container } from "@mui/material";
import { urlEndpoint } from "apiHandler/config";

import theme from "assets/theme";
import Header from "components/Header/Header";
import AddMessage from "container/AddMessage";
import ViewMessage from "container/ViewMessage";
import data from "data/data";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

import routes, { RequireAuth } from "routes";
import { readFromLocalStorage, writeToLocalStorage } from "services/localStorage";
import uploadToS3 from "services/uploadToS3";

const url = `https://xane-user-data.s3.amazonaws.com/vda/${urlEndpoint}.json`;

function App() {
  const [sop, setSOPData] = useState(readFromLocalStorage(`${urlEndpoint}`) || null);
  // uploadToS3(readFromLocalStorage("vda_data"));
  const muiTheme = createTheme(theme);
  const fetchData = async () => {
    try {
      const response = await fetch(url);
      const convertedData = await response.json();
      await writeToLocalStorage(`${urlEndpoint}`, convertedData);
      setSOPData(readFromLocalStorage(`${urlEndpoint}`));
    } catch (err) {
      alert(err);
      console.error(err);
    }
  };

  useEffect(() => {
    fetchData();
    // console.log(vdaData);
    // writeToLocalStorage("vda_data", vdaData);
    // if (!readFromLocalStorage("vda_data")) writeToLocalStorage("vda_data", data);
  }, []);

  return (
    <ThemeProvider theme={muiTheme}>
      <CssBaseline />
      <Header title="VDA - Content Management System" />
      {sop ? (
        <Container maxWidth="md">
          <Routes>
            <Route
              exact
              path="/"
              element={
                <AddMessage stepName="Step 1. Calibration" navigateTo="/camera/calibration" />
              }
            />
            <Route
              exact
              path="/view"
              element={
                <ViewMessage stepName="Step 1. Calibration" navigateTo="/camera/calibration" />
              }
            />
          </Routes>
        </Container>
      ) : (
        "Loading..."
      )}
    </ThemeProvider>
  );
}

export default App;
