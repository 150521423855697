import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Select from "react-select";
import { readFromLocalStorage, writeToLocalStorage } from "services/localStorage";
import { sopsList, environment } from "apiHandler/config";
import { IconButton } from "@mui/material";
import { Preview, Visibility } from "@mui/icons-material";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    // borderBottom: "1px dotted pink",
    color: state.isSelected ? "black" : "blue",
    // padding: 20,
  }),
  control: (provided) => ({ ...provided }),
  singleValue: (provided, state) =>
    // const opacity = state.isDisabled ? 0.5 : 1;
    // const transition = "opacity 300ms";

    ({ ...provided }),
};

export default function Header({ title }) {
  const [selectedSop, setSelectedSop] = React.useState(
    readFromLocalStorage("selected_sop") || sopsList[0]
  );
  React.useEffect(() => {
    if (!readFromLocalStorage("selected_sop")) writeToLocalStorage("selected_sop", sopsList[0]);
  }, []);

  const handleChange = async (value) => {
    await writeToLocalStorage("selected_sop", value);
    await setSelectedSop(value);
    window.location.reload();
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar sx={{ height: "10vh" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              width: "100%",
            }}
          >
            <Typography variant="h6" component="div" sx={{ textAlign: "center", width: "80%" }}>
              {title}
            </Typography>
            <IconButton
              aria-label="preview"
              onClick={() => {
                let env = environment;
                if (environment === "prod") {
                  env = "demo";
                }
                if (environment === "dev") {
                  env = "stage";
                }
                window.open(`https://${env}.vda.platform.xane.ai/`);
              }}
            >
              <Visibility sx={{ color: "white" }} />
            </IconButton>
            <Box sx={{ width: "20%" }}>
              <Select
                style={{ width: "100%" }}
                styles={customStyles}
                // className="basic-single"
                // classNamePrefix="select"
                onChange={handleChange}
                value={selectedSop}
                disabled
                defaultValue={sopsList[0]}
                // isDisabled={disabled}
                // isLoading={isLoading}

                isSearchable
                placeholder="Select SOP"
                name="color"
                options={sopsList}
              />
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
