import React, { useState } from "react";
import Select from "react-select";

function SelectInput({
  selectedValue,
  responseType,
  subIndex,
  options,
  disabled,
  handleChangeValue,
  componentType,
  index,
  superIndex,
}) {
  const [selectValue, setSelectValue] = useState(selectedValue);
  const handleChange = (value) => {
    const newValue = { ...value, text: value.label };
    setSelectValue(newValue);
    handleChangeValue(newValue, "type", index, responseType, subIndex, superIndex);
  };

  return (
    <div>
      {" "}
      <Select
        style={{ width: "100%" }}
        className="basic-single"
        classNamePrefix="select"
        onChange={handleChange}
        value={selectValue}
        // disabled={disabled}
        // defaultValue={options[0]}
        isDisabled={disabled}
        // isLoading={isLoading}
        // isClearable
        // isRtl={isRtl}
        isSearchable
        name="color"
        options={options}
      />
    </div>
  );
}

export default SelectInput;
