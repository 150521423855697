/* eslint-disable no-underscore-dangle */
import { getUniqueID } from "components/AddMessage/Message/Message";
import React from "react";

import CreatableSelect from "react-select/creatable";

function Creatable({
  options,
  disabled,
  handleChangeValue,
  index,
  sectionType,
  optionIndexOfResponse,
  SelectedValue,
}) {
  const handleChange = (value, actionMeta) => {
    let data = { ...value, text: value.label };
    if (value.__isNew__) {
      data = { ...data, id: getUniqueID() };
    }
    handleChangeValue(data, "content", index, optionIndexOfResponse);
  };

  return (
    <CreatableSelect
      isClearable
      value={SelectedValue}
      onChange={handleChange}
      isDisabled={disabled}
      defaultValue={options[0]}
      options={options}
    />
  );
}

export default Creatable;
