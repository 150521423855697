/* eslint-disable no-plusplus */
import { Button, Divider, LinearProgress, TextField, Typography } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { Box } from "@mui/system";
import SelectInput from "components/Select/SelectInput";
import React, { useEffect, useState } from "react";
import CreatableSelect from "components/Select/CreatableSelect";
import Message, { getUniqueID, responseTypes } from "components/AddMessage/Message/Message";
import Loader from "components/Loader/Loader";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { readFromLocalStorage, writeToLocalStorage } from "services/localStorage";
import { getHeaders } from "view/ViewMessage";
import { urlEndpoint } from "apiHandler/config";

const dataTypes = [
  { value: "image", label: "image", text: "image" },
  { value: "videoCall", label: "video call", text: "video call" },
  { value: "text", label: "text", text: "text" },
  { value: "voiceInput", label: "voice", text: "voice" },
  { value: "camera", label: "camera", text: "camera" },
  { value: "select", label: "select", text: "select" },
  { value: "multi-select", label: "multi-select", text: "multi-select" },
];

const initialData = {
  message: [{ type: { value: "text", label: "text", text: "text" }, content: "" }],
  response: [
    {
      type: { value: "text", label: "text", text: "text" },
      content: [
        // { value: "Not Good", label: "Not Good", id: getUniqueID() },
        // { value: "Okay", label: "Okay", id: getUniqueID() },
      ],
    },
  ],
};

function AddMessage() {
  const [data, setData] = useState({ message: [], response: [] });
  const [loader, setLoader] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  useEffect(() => {
    if (state && state.editingData) setData(state.editingData);
  }, []);
  const getNewMessageKey = () => {
    console.log(urlEndpoint);
    console.log(readFromLocalStorage(urlEndpoint));
    const vdaData = readFromLocalStorage(urlEndpoint);

    const keys = getHeaders(vdaData);

    let key = keys.length > 0 ? Math.max(...keys) + 1 : 1;
    if (state && state.editingData) {
      key = state.editingData.id;
    }

    return key;
  };
  const handlePostData = async () => {
    let temp = data;
    if (
      data.response.length > 0 &&
      responseTypes.includes(data.response.at(-1).type.value) &&
      data.response.at(-1).content.at(-1).value === ""
    ) {
      return;
    }
    if (data.message.length > 0 && data.message.at(-1).content === "") {
      return;
    }

    if (data.message.length > 0 && data.response.length < 1) {
      temp.response = [{ type: "null", content: "null" }];
    }

    temp = {
      ...temp,
      message: temp.message.map((msg) => ({ ...msg, type: msg.type.value })),
      response: temp.response.map((res) => ({ ...res, type: res.type.value })),
    };

    setLoader(true);
    const vdaData = readFromLocalStorage(urlEndpoint);

    const keys = getHeaders(vdaData);

    let newKeyForMessage = keys.length > 0 ? Math.max(...keys) + 1 : 1;
    if (state && state.editingData) {
      newKeyForMessage = temp.id;
    }
    if (temp.response[0].type === undefined) {
      temp.response[0].type = null;
      temp.response[0].content = [];
    }

    vdaData[newKeyForMessage] = temp;

    await writeToLocalStorage(urlEndpoint, vdaData);

    setData({ message: [], response: [] });

    setLoader(false);
    const alertMessage =
      state && state.editingData ? "Successfully edited" : "Successfully Created";
    alert(alertMessage);
    if (state && state.editingData) {
      navigate("/view");
    }
  };
  const handleUpdate = (updatedData, componentType) => {
    const temp = data;
    temp[componentType.toLowerCase()] = updatedData;

    setData({ ...temp });
  };

  return (
    <Box>
      {loader ? (
        <LinearProgress />
      ) : (
        <>
          <Message
            data={data.message}
            componentName="Message"
            handleData={handleUpdate}
            initialData={initialData.message[0]}
            dataTypes={dataTypes.slice(0, 3)}
          />
          <Message
            data={data.response}
            componentName="Response"
            handleData={handleUpdate}
            initialData={initialData.response[0]}
            dataTypes={dataTypes.slice(2, 7)}
          />
          <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
            {data.message.length > 0 || data.response.length > 0 ? (
              <Button
                variant="contained"
                color="warning"
                sx={{ marginTop: "15px" }}
                onClick={handlePostData}
              >
                {" "}
                Save{" "}
              </Button>
            ) : null}
            <Typography
              variant="Caption"
              gutterBottom
              sx={{
                textAlign: "center",
                marginTop: "15px",
                border: "2px solid grey",
                padding: "5px",
                borderRadius: "10px",
              }}
            >
              Id: {getNewMessageKey()}
            </Typography>
            <Link to="/view">
              {" "}
              <Button variant="contained" sx={{ float: "right", marginTop: "15px" }}>
                View Messages
              </Button>
            </Link>
          </Box>
        </>
      )}
    </Box>
  );
}

export default AddMessage;
