/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
/* eslint-disable eqeqeq */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-lone-blocks */
/* eslint-disable guard-for-in */
/* eslint-disable no-console */
/* eslint-disable no-restricted-syntax */

import React, { useEffect, useState } from "react";
// import data from "data/data";
import { Box } from "@mui/system";
import {
  Avatar,
  Button,
  CardMedia,
  Chip,
  Divider,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import { getUniqueID } from "components/AddMessage/Message/Message";
import { Delete, Edit, Remove } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import SelectInput from "components/Select/SelectInput";
import { readFromLocalStorage, writeToLocalStorage } from "services/localStorage";
import uploadToS3 from "services/uploadToS3";
import { urlEndpoint } from "apiHandler/config";

const iterativeResponseTypes = ["select", "multi-select"];

export function getHeaders(obj) {
  return Object.keys(obj);
}

function ViewMessage() {
  const [data, setData] = useState(readFromLocalStorage(urlEndpoint));
  const [convertedData, setConvertedData] = useState([]);
  const [routingOptions, setRoutingOptions] = useState([]);
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();

  const getData = async () => {
    const keys = getHeaders(data);
    const temp = [...convertedData];
    for (const key in data) {
      await temp.push({ ...data[key], id: key });
    }

    const tempRoutingOptions = temp.map((msg, index) => ({
      value: msg.id,
      label: `(${msg.id}) ${msg.message[0].content}`,
    }));
    await setRoutingOptions([...tempRoutingOptions]);
    const afterAddingIDs = temp.map((item) => ({
      ...item,
      // id: getUniqueID(),
      message: item.message.map((msg) => ({
        ...msg,
        type: { value: msg.type, label: msg.type },
        id: getUniqueID(),
      })),
      response: item.response.map((res) => ({
        ...res,
        id: getUniqueID(),
        type: { value: res.type, label: res.type },
        content:
          res.content && res.content.length > 0
            ? res.content.map((cont) => ({ ...cont, label: cont.text, id: getUniqueID() }))
            : [],
      })),
    }));

    await setConvertedData([...afterAddingIDs]);
    // console.log(temp);
  };
  useEffect(() => {
    setLoader(true);
    getData();

    setLoader(false);
  }, []);

  const handleChangeRouting = (newValue, type, index, responseType, subIndex, superIndex) => {
    const vdaData = readFromLocalStorage(urlEndpoint);
    let routeArray = [];
    if (responseType === "voiceInput") {
      vdaData[index].nextMessageId = newValue.value;
    }
    if (responseType === "camera") {
      console.log(vdaData[index]);
      if (vdaData[index].nextMessageId) {
        routeArray = [...vdaData[index].nextMessageId];
      }
      routeArray[subIndex] = newValue.value;
      console.log(routeArray);
      vdaData[index].nextMessageId = routeArray;
    }
    if (responseType === "select") {
      vdaData[index].response[superIndex].content[subIndex].nextMessageId = newValue.value;
    }
    writeToLocalStorage(urlEndpoint, vdaData);
    // window.location.reload();
  };

  if (convertedData.length > 0) {
    return (
      <Box>
        <Link to="/" style={{ textDecoration: "none" }}>
          {" "}
          <Button
            variant="contained"
            sx={{
              // float: "left",
              marginTop: "15px",
              marginBottom: "15px",
              // position: "absolute",
            }}
          >
            Create
          </Button>
        </Link>
        <Button
          variant="contained"
          sx={{
            float: "right",
            marginTop: "15px",
            marginBottom: "15px",
            // position: "absolute",
          }}
          onClick={async () => {
            const vdaJson = await readFromLocalStorage(urlEndpoint);

            const selectedSOP = readFromLocalStorage("selected_sop");
            if (confirm(`Your data will be posted to "${selectedSOP.label}" `)) {
              await uploadToS3(vdaJson);
              alert("Content has been successfully sent to the server");
              location.reload();
            }
            // else {
            // }
          }}
        >
          Send to server
        </Button>
        <Box
          sx={{
            height: "80vh",

            border: "2px solid #D3D3D3",
            padding: "15px",
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{
              height: "75vh",
              overflow: "hidden",
              overflowY: "scroll",
            }}
          >
            {convertedData &&
              convertedData.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    background: "#F5F5F5",
                    padding: "10px",
                    marginTop: "15px",
                    marginBottom: "17px",
                    borderRadius: "5px",
                    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                  }}
                >
                  <Box sx={{ border: "0px solid #D3D3D3", padding: "0px 10px" }}>
                    <Typography variant="h6" gutterBottom sx={{ display: "inline-block" }}>
                      {" "}
                      {index + 1}
                    </Typography>

                    <IconButton
                      sx={{ float: "right" }}
                      onClick={async () => {
                        const result = confirm("Want to delete?");
                        if (result) {
                          const temp = await readFromLocalStorage(urlEndpoint);
                          delete temp[item.id];
                          const tempConvertedData = convertedData;
                          tempConvertedData.splice(index, 1);
                          setConvertedData([...tempConvertedData]);
                          await writeToLocalStorage(urlEndpoint, temp);
                        }
                      }}
                    >
                      {/* <Link> */}
                      <Delete fontSize="small" />
                      {/* </Link> */}
                    </IconButton>
                    <Link to="/" state={{ editingData: item }}>
                      {" "}
                      <IconButton sx={{ float: "right" }}>
                        {/* <Link> */}
                        <Edit fontSize="small" />
                        {/* </Link> */}
                      </IconButton>
                    </Link>
                  </Box>
                  <Divider sx={{ width: "100%" }} />
                  <Typography variant="caption" sx={{ float: "right" }} gutterBottom>
                    id: {item.id}
                  </Typography>
                  <ol>
                    {item.message.map((msg, i) => (
                      <li key={index + i}>
                        <Typography variant="body2" gutterBottom>
                          {msg.type.label === "image" ? (
                            <Avatar
                              sx={{ height: "auto", width: "100px", cursor: "pointer" }}
                              variant="rounded"
                              src={msg.content.split(" ").join("")}
                              alt={msg.content}
                              onClick={() => {
                                window.open(msg.content.split(" ").join(""));
                              }}
                            />
                          ) : (
                            ` ${msg.content}`
                          )}
                          <br />
                        </Typography>
                      </li>
                    ))}
                  </ol>
                  {item.response.map((res, j) => (
                    <Typography key={index + j} variant="body2" gutterBottom>
                      <Chip label={res.type.label} />
                      {res.type.value === "voiceInput" || res.type.value === "text" ? (
                        <Box
                          sx={{
                            width: "22vw",
                            float: "right",
                          }}
                        >
                          <SelectInput
                            options={routingOptions}
                            index={item.id}
                            responseType="voiceInput"
                            handleChangeValue={handleChangeRouting}
                            selectedValue={
                              item.nextMessageId &&
                              routingOptions.filter(
                                (route) =>
                                  // console.log(option, "666666666666666");
                                  route.value ==
                                  (Array.isArray(item.nextMessageId)
                                    ? item.nextMessageId[0]
                                    : item.nextMessageId)
                              )
                            }
                          />
                        </Box>
                      ) : null}

                      {res.type.value === "camera" ? (
                        <Box
                          sx={{
                            width: "20vw",
                            float: "right",
                          }}
                        >
                          <Box>
                            <SelectInput
                              options={routingOptions}
                              index={item.id}
                              subIndex={0}
                              handleChangeValue={handleChangeRouting}
                              responseType="camera"
                              selectedValue={
                                item.nextMessageId &&
                                routingOptions.filter(
                                  (route) =>
                                    route.value ==
                                    (Array.isArray(item.nextMessageId)
                                      ? item.nextMessageId[0]
                                      : item.nextMessageId)
                                )
                              }
                            />
                          </Box>
                          <Box>
                            <SelectInput
                              options={routingOptions}
                              index={item.id}
                              responseType="camera"
                              subIndex={1}
                              handleChangeValue={handleChangeRouting}
                              selectedValue={
                                item.nextMessageId &&
                                routingOptions.filter(
                                  (route) =>
                                    // console.log(option, "666666666666666");
                                    route.value ==
                                    (Array.isArray(item.nextMessageId)
                                      ? item.nextMessageId[1]
                                      : item.nextMessageId)
                                )
                              }
                            />
                          </Box>
                        </Box>
                      ) : null}

                      <ul>
                        {iterativeResponseTypes.includes(res.type.label) &&
                          res.content.map((option, k) => (
                            <li key={index + k + j}>
                              <Box
                                sx={{
                                  display: "flex",
                                  width: "50vw",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography variant="body2" gutterBottom>
                                  {option.value}
                                </Typography>
                                <Box
                                  sx={{
                                    width: "20vw",
                                  }}
                                >
                                  <SelectInput
                                    responseType="select"
                                    subIndex={k}
                                    options={routingOptions}
                                    superIndex={j}
                                    index={item.id}
                                    handleChangeValue={handleChangeRouting}
                                    selectedValue={
                                      option.nextMessageId &&
                                      routingOptions.filter(
                                        (route) =>
                                          // console.log(option, "666666666666666");
                                          route.value == option.nextMessageId
                                      )
                                    }
                                  />
                                </Box>
                              </Box>
                            </li>
                          ))}
                      </ul>
                    </Typography>
                  ))}
                </Box>
              ))}
          </Box>
        </Box>
      </Box>
    );
  }
  // {
  //                                 value: item.id,
  //                                 label: `${index}. ${item.message[0].content}`,
  //                               }

  return <LinearProgress />;
}

export default ViewMessage;
